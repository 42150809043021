import React from "react"
import { Link } from "gatsby"

import HeroHome from "../components/hero_home"
import ResourcePic1 from "../static/images/resources/media-560.jpeg"
import ResourcePic2 from "../static/images/resources/resources_02-560x320.jpg"
import ResourcePic3 from "../static/images/resources/resources_03-560x320.jpg"
import ResourcePic4 from "../static/images/resources/resources_04-560x320.jpg"

export default function Home() {
  return (
    <>
      <HeroHome />
      <section class="c-whyus c-whyus-sm">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-lg-6 col-md-6">
              <div class="c-content-padding c-content-padding-sm">
                <h5 class="h2 h2-sm text--blue mb-4">Why Choose Us</h5>
                <h2 class="h5 h5-sm mb-2">For Simplicty. And:</h2>
                <ul class="list-icon list-icon-check list-icon-colored">
                  <li>
                    Acting responsibly in your best interest by offering high quality and
                    conflicts-free advice
                  </li>
                  <li>
                    Demonstrating alignment of interest through co-investment, to the extent
                    feasible
                  </li>
                  <li>Disciplined and value-oriented approach to long term investment selection</li>
                  <li>Focus on risk management and capital preservation</li>
                  <li>Sensible, transparent and flexible fee philosophy</li>
                  <li>
                    Clearly defined and unwavering dedication to client service to allow you as a
                    client to focus on your career/business
                  </li>
                  {/* <li>Association with iFast Global Markets</li> */}
                </ul>
                <div class="cta w-100 mt-4">
                  <Link
                    to="/contact-us"
                    class="c-button c-button-inline c-button-md c-button--blue c-button-radius"
                  >
                    Get in Touch
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6">
              <div class="blockquote blockquote-fancy blockquote-sm blockquote-fancy-sm">
                <p>
                  That’s been one of my mantras – focus and simplicity. Simple can be harder than
                  complex.
                </p>
                <cite>— Steve Jobs</cite>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="clearfix"></div>

      <section class="c-section-grey c-section-grey-sm">
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-lg-7 col-md-7">
              <div class="w-100 pr-md-5 sm:mmb-5">
                <div class="embed-responsive embed-responsive-16by9 radius-sm">
                  <iframe
                    title="Interview"
                    class="embed-responsive-item"
                    src="https://www.youtube.com/embed/IB_qKoklFJk?rel=0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5 col-md-5">
              <div class="c-content-padding c-content-padding-sm">
                <h5 class="h2 h2-sm text--blue mb-4">Interview with CNBC</h5>
                <ul class="list-icon list-icon-check list-icon-colored">
                  <li>
                    Discussion centered around investing in thematic and sectoral ETFs and mutual
                    funds.
                  </li>
                  <li>
                    VCL framework: volatility, conviction and liquidity are key considerations.
                  </li>
                  <li>Investable ideas for 2022.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="clearfix"></div>

      <section class="c-section-grey c-section-grey-sm" style={{ backgroundColor: "#F4F4F4" }}>
        <div class="container">
          <div class="row justify-content-center mb-3">
            <div class="col-12 col-lg-8 col-md-8 text-center">
              <h2 class="h2 h2-sm text--blue">Are you?</h2>
            </div>
          </div>
          <div class="four-column">
            <div class="row">
              <Link to="/clients/business-owner" class="col-sm-3">
                <div class="item">
                  <h4 class="h4 h4-sm text--blue mb-2">A Business Owner</h4>
                  <p class="text--grey">
                    At Apricus, we are conscious that you are focussed on building your business.
                    Thinking about your work, employees...
                  </p>
                </div>
              </Link>
              <Link to="/clients/professional-salaried-employee" class="col-sm-3">
                <div class="item">
                  <h4 class="h4 h4-sm text--blue mb-2">A Professional</h4>
                  <p class="text--grey">
                    Having worked as a corporate lawyer and being privy to the life of most sets of
                    professionals...
                  </p>
                </div>
              </Link>
              <Link to="/clients/woman" class="col-sm-3">
                <div class="item">
                  <h4 class="h4 h4-sm text--blue mb-2">A Woman</h4>
                  <p class="text--grey">
                    The financial needs and requirements of women generally differ from those of
                    men. Generally, females...
                  </p>
                </div>
              </Link>
              <Link to="/clients/retirees" class="col-sm-3">
                <div class="item">
                  <h4 class="h4 h4-sm text--blue mb-2">A Retiree</h4>
                  <p class="text--grey">
                    Retirement is when you are winding down after a long and fruitful career. We can
                    assist you in formulating...
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div class="clearfix"></div>

      <section class="c-section c-section-sm">
        <div class="container">
          <div class="row justify-content-center mb-4">
            <div class="col-12 col-lg-8 col-md-8 text-center">
              <h2 class="h2 h2-sm text--blue mb-4">Resources</h2>
            </div>
          </div>
          <div class="row">
            {/* <div class="col-12 col-lg-3 col-md-3">
              <Link to="/resources/blog" class="post-wrapper">
                <div class="post-featured-image">
                  <figure class="post-featured-image-hover">
                    <img src={ResourcePic1} alt="Investment Letters" class="img-fluid" />
                  </figure>
                </div>
                <div class="post-content-wrapper">
                  <h3 class="h4 h4-sm mb-1">Investment Letters</h3>
                  <p class="text--grey clamp2">
                    Periodic investment letters for the family portfolio reviewing our investment
                    strategy, performance and other important issues. Our direct equities advisory
                    will follow a similar approach as set out in these letters
                  </p>
                </div>
              </Link>
            </div> */}

            <div class="col-12 col-lg-3 col-md-3">
              <Link to="/resources/blog" class="post-wrapper">
                <div class="post-featured-image">
                  <figure class="post-featured-image-hover">
                    <img src={ResourcePic2} alt="Blog" class="img-fluid" />
                  </figure>
                </div>
                <div class="post-content-wrapper">
                  <h3 class="h4 h4-sm mb-1">Blog</h3>
                  <p class="text--grey clamp2">
                    Ideas, latest investment insights and finance related musings
                  </p>
                </div>
              </Link>
            </div>

            <div class="col-12 col-lg-3 col-md-3">
              <Link to="/resources/media" class="post-wrapper">
                <div class="post-featured-image">
                  <figure class="post-featured-image-hover">
                    <img src={ResourcePic1} alt="Media" class="img-fluid" />
                  </figure>
                </div>
                <div class="post-content-wrapper">
                  <h3 class="h4 h4-sm mb-1">Media</h3>
                  <p class="text--grey clamp2">Our media mentions and press outreach initiatives</p>
                </div>
              </Link>
            </div>

            <div class="col-12 col-lg-3 col-md-3">
              <Link to="/resources/tools" class="post-wrapper">
                <div class="post-featured-image">
                  <figure class="post-featured-image-hover">
                    <img src={ResourcePic3} alt="Tools" class="img-fluid" />
                  </figure>
                </div>
                <div class="post-content-wrapper">
                  <h3 class="h4 h4-sm mb-1">Tools</h3>
                  <p class="text--grey clamp2">
                    Quick check to understand your financial health and risk tolerance
                  </p>
                </div>
              </Link>
            </div>

            <div class="col-12 col-lg-3 col-md-3">
              <Link to="/resources/book-summaries" class="post-wrapper">
                <div class="post-featured-image">
                  <figure class="post-featured-image-hover">
                    <img src={ResourcePic4} alt="Book Summaries" class="img-fluid" />
                  </figure>
                </div>
                <div class="post-content-wrapper">
                  <h3 class="h4 h4-sm mb-1">Book Summaries</h3>
                  <p class="text--grey clamp2">Learnings from other people</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div class="clearfix"></div>
    </>
  )
}
