import React, { useRef } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import Anime from "react-anime"

// Import css files
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function HeroHome() {
  const pictureRef = useRef(null)
  const pictureRef2 = useRef(null)
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    cssEase: "linear",
    pauseOnHover: true,
  }
  return (
    <div class="o-content-slider">
      <div id="heroslider" class="o-media-hero relative">
        <div class="o-media-slider">
          <Slider {...settings}>
            <div>
              <Anime
                easing="easeOutExpo"
                duration={1200}
                direction="alternate"
                loop={false}
                delay={(el, index) => index * 240}
                translateX="0rem"
                scale={[1.6, 1]}
                opacity={[0, 1]}
                update={anime => {
                  pictureRef2.current.style.filter = "blur(" + (2000 * anime.progress) / 0 + "px)"
                }}
              >
                <div class="hero--overlay"></div>
                <img
                  ref={pictureRef2}
                  class="image-cover"
                  src="/images/slider/01.jpg"
                  alt="Sunshine For Growing Your Wealth"
                />
              </Anime>
              <div class="o-media-hero_overlay">
                <div class="o-media-hero_caption">
                  <div class="o-media-hero_caption_balance">
                    <div class="container">
                      <div class="o-cards-view__container o-cards-view__container-xs">
                        <div class="o-cards-view__info apricus-main-title">
                          <h1 class="o-cards-view__info--heading o-cards-view__info--heading-xs">
                            Sunshine For Growing Your Wealth
                          </h1>
                          <p class="o-cards-view__info--description o-cards-view__info--description-xs">
                            Wealth Management | Investment Advisory
                          </p>
                          <div class="o-cards-view__info--cta o-cards-view__info--cta-sm mt-3">
                            <Link
                              to="/services/offerings"
                              className={
                                "c-button c-button-sm c-button-radius c-button--blue c-button-inline c-button-md"
                              }
                            >
                              Offerings
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Anime
                easing="easeOutExpo"
                duration={1200}
                direction="alternate"
                loop={false}
                delay={(el, index) => index * 240}
                translateX="0rem"
                scale={[1.6, 1]}
                opacity={[0, 1]}
                update={anime => {
                  pictureRef.current.style.filter = "blur(" + (2000 * anime.progress) / 0 + "px)"
                }}
              >
                <div class="hero--overlay"></div>
                <img
                  ref={pictureRef}
                  class="image-cover"
                  src="/images/slider/02.jpg"
                  alt="Risk Profiling"
                />
              </Anime>
              <div class="o-media-hero_overlay">
                <div class="o-media-hero_caption">
                  <div class="o-media-hero_caption_balance">
                    <div class="container">
                      <div class="o-cards-view__container o-cards-view__container-xs">
                        <div class="o-cards-view__info apricus-main-title">
                          <h1 class="o-cards-view__info--heading o-cards-view__info--heading-xs">
                            5 Minute Financial Health Check
                          </h1>
                          <p class="o-cards-view__info--description o-cards-view__info--description-xs">
                            Understand your financial health condition to start your investment
                            journey
                          </p>
                          <div class="o-cards-view__info--cta o-cards-view__info--cta-sm mt-3">
                            <Link
                              to="/resources/tools/5-minute-health-check"
                              className={
                                "c-button c-button-sm c-button-radius c-button--blue c-button-inline c-button-md"
                              }
                            >
                              Health Check
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  )
}
